import {BaseGraph} from "./base_graph";

class TachoFinance extends BaseGraph {
    constructor() {
        super('tacho_finance');
    }

    getChartJsOptions(options, data) {
        options.plugins = {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const formatter = new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'})

                        if (context.dataset.label !== 'Profit/Loss') {
                            return `${context.dataset.label}: ` + formatter.format(context.parsed.y)
                        }

                        const abs = context.dataset.abs[context.dataIndex];
                        let value = context.parsed.y;
                        let label = 'Profit';

                        if (abs === 'negative') {
                            label = 'Loss'
                            value = `-${Math.abs(value)}`
                            context.parsed.y = value;
                        }

                        label += `: ${formatter.format(value)}`;

                        return label;
                    }
                }
            }
        };
        
        return options;
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new TachoFinance();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});